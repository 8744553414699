import React from 'react'
import Index from '../../Index'


export default function AuthBackground() {
      return (
            <>
                  <Index.Box className="admin-auth-left-bg-main">
                        <Index.Box className="admin-auth-left-bg"></Index.Box>
                  </Index.Box>
            </>
      )
}
