// import loginVideo from './images/video/bg-1.mp4';
// import loginVideo2 from './images/video/bg-2.mp4';
// import loginVideo3 from './images/video/bg-3.mp4';
// import loginVideo4 from './images/video/bg-4.mp4';
// import loginVideo5 from './images/video/bg-5.mp4';
// import loginVideo6 from './images/video/bg-6.mp4';
import authVideo from './images/video/auth-video.mp4';

const Video = {
    // loginVideo,
    // loginVideo2,
    // loginVideo3,
    // loginVideo4,
    // loginVideo5,
    // loginVideo6,
    authVideo
}

export default Video;